import React from 'react';
import { List, Avatar, Radio, Typography, Input, Button } from 'antd';
import { RobotFilled, RobotOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import '../css/pageList.css'; // Import the CSS file for additional styling

const PageListComponent = ({ pages, selectedPage, onPageSelect }) => {
  const screens = useBreakpoint();

  return (
    <List
      className={screens.xs ? 'full-width-list' : ''}
      style={{ minWidth: '50vw' }}
      itemLayout="horizontal"
      dataSource={pages}
      renderItem={page => (
        <List.Item
          className="responsive-list-item"
          style={{
            backgroundColor: selectedPage === page.id ? '#f0f0f0' : 'transparent',
            cursor: 'pointer',
            borderRadius: '10px',
            marginBottom: '20px',
            border: selectedPage === page.id ? '1px solid #d9d9d9' : 'none',
            flexDirection: screens.xs ? 'column' : 'row',
            alignItems: screens.xs ? 'flex-start' : 'center',
          }}
          onClick={() => onPageSelect(page.id)}
        >
          <List.Item.Meta
            avatar={<Avatar src={page.picture.data.url} size={screens.xs ? 48 : 64} />}
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: screens.xs ? 'flex-start' : 'center', width: '100%' }}>
                <div>
                  <Radio.Group onChange={() => onPageSelect(page.id)} value={selectedPage}>
                    <Radio className="responsive-radio" value={page.id}>
                      {page.name}
                    </Radio>
                  </Radio.Group>
                  <Typography.Text className="responsive-text">Page ID: {page.id}</Typography.Text><br />
                  <Typography.Link className="responsive-link" href={`https://facebook.com/${page.id}`} target="_blank">View Page</Typography.Link>
                </div>
                {page.hasSubscription && !screens.xs && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      addonBefore={
                        <Button
                          type="link"
                          href={`https://m.me/${page.id}?ref=chatbot`}
                          target="_blank"
                          style={{ maxWidth: '100%', display: 'flex', alignItems: 'center' }}
                        >
                          <img src='/messenger.png' alt="Messenger Icon" style={{ width: '18px', height: '18px', marginRight: '8px', verticalAlign: 'middle' }} />
                          Test với Messenger
                        </Button>
                      }
                      value={`https://m.me/${page.id}?ref=chatbot`}
                      readOnly
                      style={{ width: 'auto', flex: '1' }}
                    />
                    <RobotFilled style={{ color: 'blue', fontSize: '32px', marginLeft: '10px' }} />
                  </div>
                )}
                {!page.hasSubscription && (
                  <RobotOutlined style={{ fontSize: '32px' }} />
                )}
              </div>
            }
          />
          {screens.xs && page.hasSubscription && (
            <div style={{ marginTop: '10px', width: '100%' }}>
              <Button
                type="link"
                href={`https://m.me/${page.id}?ref=chatbot`}
                target="_blank"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src='/messenger.png' alt="Messenger Icon" style={{ width: '18px', height: '18px', marginRight: '8px', verticalAlign: 'middle' }} />
                Test với Messenger
              </Button>
            </div>
          )}
        </List.Item>
      )}
    />
  );
};

export default PageListComponent;
