import React from 'react';
import { Layout } from 'antd';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';

const AppLayout = ({ children, showLogout = true }) => {
  return (
    <Layout style={{ minHeight: '100vh', width: '100vw' }}>
      <HeaderComponent showLogout={showLogout} />
      {children}
      <FooterComponent />
    </Layout>
  );
};

export default AppLayout;
