// HomePage.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin, Button, Alert, Layout, Typography } from 'antd';
import axios from 'axios';
import AppLayout from '../components/AppLayout';
import '../css/homepage.css'; // Import the CSS file for additional styling

const { Content } = Layout;
const { Title } = Typography;

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('fb_access_token');
    if (!token) {
      setLoading(false);
      return;
    }

    const validateToken = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_FB_GRAPH_URL}/me`, {
          params: { access_token: token },
        });
        if (response.data) {
          navigate('/pages');
        }
      } catch (error) {
        setError('Invalid access token. Please log in again.');
        sessionStorage.removeItem('fb_access_token');
      } finally {
        setLoading(false);
      }
    };

    validateToken();
  }, [navigate]);

  const handleLogin = () => {
    const redirectUri = `${window.location.origin}/oauth-callback`;
    const permissions = ['pages_messaging', 'pages_show_list', 'pages_manage_metadata', 'business_management', 'pages_messaging_subscriptions'];
    const oauthUrl = `https://www.facebook.com/dialog/oauth?response_type=token&client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}&redirect_uri=${redirectUri}&scope=${permissions.join(',')}`;
    window.location.href = oauthUrl;
  };

  if (loading) {
    return <Spin size="large" tip="Checking access token..." />;
  }

  return (
    <AppLayout showLogout={false}>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white', height: '100vh', padding: '24px' }}>
        <img src="/85z_2201_w009_n001_95c_p6_95.jpg" alt="App Illustration" style={{ marginBottom: '24px', maxHeight: '30vh', width: 'auto' }} />
        <Title level={2} style={{ textAlign: 'center', marginBottom: '24px' }}>
          Chatbot cho Facebook Page
        </Title>
        {error && <Alert message={error} type="error" style={{ marginBottom: 16 }} />}
        <Button
          type="primary"
          onClick={handleLogin}
          size="large"
          icon={<img src="/facebook_5968764.png" alt="Facebook Icon" style={{ width: '18px', height: '18px', marginRight: '8px' }} />}
          style={{ fontSize: '18px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 'fit-content' }}
        >
          Connect Facebook để sử dụng bot!
        </Button>
      </Content>
    </AppLayout>
  );
};

export default HomePage;
