import React, { useEffect, useState, useCallback } from 'react';
import { Layout, Spin, Alert, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import '../css/page.css'; // Import the CSS file

import HeaderComponent from '../components/HeaderComponent';
import PageListComponent from '../components/PageListComponent';
import ActionButtonComponent from '../components/ActionButtonComponent';
import ProcessingModal from '../components/ProcessingModal';
import FooterComponent from '../components/FooterComponent'; // Import the FooterComponent

const { Content } = Layout;
const { Title } = Typography;

const Pages = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();
  const screens = useBreakpoint(); // Define screens using useBreakpoint

  const fetchPages = useCallback(async () => {
    const token = sessionStorage.getItem('fb_access_token');
    if (!token) {
      setError('No access token found. Please log in again.');
      setLoading(false);
      navigate('/');
      return;
    }

    try {
      const pagesData = [];
      let url = `${process.env.REACT_APP_FB_GRAPH_URL}/me/accounts?access_token=${token}&fields=id,name,picture,access_token`;

      while (url) {
        const response = await axios.get(url);
        const data = response.data;
        pagesData.push(...data.data);

        // Check for the next page
        url = data.paging?.next || null;
      }

      // Check subscriptions for each page using page access tokens
      const pagesWithSubscription = await Promise.all(pagesData.map(async page => {
        try {
          const subscriptionResponse = await axios.get(`${process.env.REACT_APP_FB_GRAPH_URL}/${page.id}/subscribed_apps`, {
            params: { access_token: page.access_token },
          });

          const hasSubscription = subscriptionResponse.data.data.some(app => 
            app.id === process.env.REACT_APP_FACEBOOK_APP_ID
          );
          return {
            ...page,
            hasSubscription,
          };
        } catch (subscriptionError) {
          return {
            ...page,
            hasSubscription: false,
          };
        }
      }));

      setPages(pagesWithSubscription);
    } catch (error) {
      if (error.response && error.response.data.error && error.response.data.error.message.includes('expired')) {
        // Token has expired
        setError('Access token has expired. Please log in again.');
        sessionStorage.removeItem('fb_access_token');
        navigate('/');
      } else {
        setError('Failed to load pages. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  const handlePageSelect = (pageId) => {
    setSelectedPage(pageId);
  };

  const handleConnectDisconnect = async () => {
    setApiLoading(true);
    setResult(null);
    setModalVisible(true);

    try {
      const selectedPageObj = pages.find(page => page.id === selectedPage);
      const scope = selectedPageObj.hasSubscription ? 'unsubscribe' : 'subscribe';
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/facebook/${scope}`, {
        accessToken: sessionStorage.getItem('fb_access_token'),
        pageId: selectedPage
      });
      setResult('success');
      // Update the page state to reflect the subscription change
      const updatedPages = pages.map(page => 
        page.id === selectedPage ? { ...page, hasSubscription: scope === 'subscribe' } : page
      );
      setPages(updatedPages);
    } catch (error) {
      console.log(error);
      setResult('fail');
    } finally {
      setApiLoading(false);
      fetchPages();
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('fb_access_token');
    navigate('/');
  };

  const selectedPageObj = pages.find(page => page.id === selectedPage);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <HeaderComponent onLogout={handleLogout} showLogout={true} />
      <Content style={{ padding: screens.xs ? '0' : '0 50px', marginTop: 64, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, overflowY: 'auto', padding: screens.xs ? 0 : 24 }}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: loading ? 0 : 24 }} className="responsive-title">
            Chọn page bạn muốn cài chatbot
          </Title>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <Spin size="large" tip="Loading pages..." />
            </div>
          ) : error ? (
            <Alert message={error} type="error" />
          ) : (
            <PageListComponent pages={pages} selectedPage={selectedPage} onPageSelect={handlePageSelect} />
          )}
        </div>
        {selectedPageObj && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '24px 0' }}>
            <ActionButtonComponent 
              selectedPage={selectedPage} 
              hasSubscription={selectedPageObj.hasSubscription} 
              onConfirm={handleConnectDisconnect} 
            />
          </div>
        )}
      </Content>
      <FooterComponent />
      <ProcessingModal 
        visible={modalVisible} 
        loading={apiLoading} 
        result={result} 
        onCancel={() => setModalVisible(false)} 
        pageId={selectedPage}
        isDisconnect={selectedPageObj?.hasSubscription}
      />
    </Layout>
  );
};

export default Pages;
