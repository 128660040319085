import React from 'react';
import { Modal, Spin, Result, Input, Button } from 'antd';

const ProcessingModal = ({ visible, loading, result, onCancel, pageId, isDisconnect }) => {
  const renderResult = () => {
    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <Spin size="large" tip="Đang xử lý ..." />
        </div>
      );
    }

    if (result === 'success') {
      return (
        <Result
          status="success"
          title={!isDisconnect ? `Xóa chatbot khỏi page ${pageId} thành công!` : `Thêm chatbot vào page ${pageId} thành công!`}
          extra={[
            isDisconnect && (
              <Input
                key="test-messenger"
                addonBefore={
                  <Button type="link" href={`https://m.me/${pageId}?ref=chatbot`} target="_blank" style={{ maxWidth: '100%' }}>
                    Test với Messenger
                  </Button>
                }
                value={`https://m.me/${pageId}?ref=chatbot`}
                readOnly
                style={{ width: 'auto', flex: '1' }}
              />
            )
          ]}
        />
      );
    }

    return <Result status="error" title="Operation failed!" />;
  };

  return (
    <Modal
      open={visible}
      title="Đang xử lý ..."
      footer={null}
      onCancel={onCancel}
    >
      {renderResult()}
    </Modal>
  );
};

export default ProcessingModal;
