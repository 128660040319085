import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

const { Footer } = Layout;

const FooterComponent = () => {
  return (
    <Footer style={{ textAlign: 'center', backgroundColor: 'white' }}>
      My Chatbot App ©2024 Created by Quan Tran | <Link to="/policy">Chính sách bảo mật</Link>
    </Footer>
  );
};

export default FooterComponent;
