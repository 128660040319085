import React from 'react';
import { Layout, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../components/AppLayout'; // Make sure to import AppLayout

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const PolicyPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/pages');
  };

  return (
    <AppLayout showLogout={false}> {/* Use AppLayout with showLogout as false */}
      <Content style={{ padding: '50px', backgroundColor: 'white' }}>
        <Title level={2} style={{ textAlign: 'center' }}>Chính sách bảo mật của ứng dụng Chatbot cho Facebook Page</Title>
        <Paragraph>
          <Title level={4}>1. Giới thiệu</Title>
          Chúng tôi cam kết bảo vệ quyền riêng tư và bảo mật thông tin cá nhân của bạn. Chính sách này giải thích cách chúng tôi thu thập, sử dụng, và bảo vệ thông tin của bạn khi bạn sử dụng ứng dụng Chatbot cho Facebook Page.
        </Paragraph>
        <Paragraph>
          <Title level={4}>2. Thông tin chúng tôi thu thập</Title>
          <ul>
            <li><b>Thông tin cá nhân:</b> Chúng tôi có thể thu thập các thông tin cá nhân như tên, địa chỉ email, và số điện thoại khi bạn cung cấp thông tin này một cách tự nguyện.</li>
            <li><b>Dữ liệu từ Facebook:</b> Chúng tôi thu thập các thông tin liên quan đến tài khoản Facebook của bạn, bao gồm tên trang, ID trang, và thông tin đăng nhập để có thể hoạt động với các trang Facebook của bạn.</li>
          </ul>
        </Paragraph>
        <Paragraph>
          <Title level={4}>3. Cách chúng tôi sử dụng thông tin</Title>
          <ul>
            <li><b>Cải thiện dịch vụ:</b> Sử dụng thông tin để cải thiện và cá nhân hóa trải nghiệm của bạn với chatbot.</li>
            <li><b>Giao tiếp:</b> Gửi thông tin cập nhật, thông báo và các tin nhắn liên quan đến dịch vụ của chúng tôi.</li>
            <li><b>Bảo mật:</b> Bảo vệ chống lại gian lận và lạm dụng dịch vụ của chúng tôi.</li>
          </ul>
        </Paragraph>
        <Paragraph>
          <Title level={4}>4. Chia sẻ thông tin</Title>
          Chúng tôi không bán, trao đổi hoặc chuyển giao thông tin cá nhân của bạn cho bên thứ ba mà không có sự đồng ý của bạn, trừ khi yêu cầu theo luật pháp.
        </Paragraph>
        <Paragraph>
          <Title level={4}>5. Bảo mật thông tin</Title>
          Chúng tôi sử dụng các biện pháp bảo mật hợp lý để bảo vệ thông tin cá nhân của bạn khỏi bị truy cập trái phép, mất mát, hoặc tiết lộ.
        </Paragraph>
        <Paragraph>
          <Title level={4}>6. Quyền của bạn</Title>
          Bạn có quyền truy cập, chỉnh sửa hoặc xóa thông tin cá nhân của mình. Bạn cũng có thể yêu cầu chúng tôi ngừng sử dụng thông tin của bạn.
        </Paragraph>
        <Paragraph>
          <Title level={4}>7. Liên hệ</Title>
          Nếu bạn có bất kỳ câu hỏi nào về chính sách bảo mật này, vui lòng liên hệ với chúng tôi qua email tại support@chatbotapp.com.
        </Paragraph>
        <Paragraph>
          <Title level={4}>8. Thay đổi chính sách</Title>
          Chúng tôi có thể cập nhật chính sách bảo mật này theo thời gian. Mọi thay đổi sẽ được thông báo trên trang này.
        </Paragraph>
        <Button type="primary" onClick={handleBack} style={{ marginTop: '20px' }}>
          Quay về
        </Button>
      </Content>
    </AppLayout>
  );
};

export default PolicyPage;
