// App.js hoặc Routes.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Pages from './pages/Pages';
import OAuthCallback from './pages/OAuthCallback';
import PolicyPage from './pages/PolicyPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/pages" element={<Pages />} />
        <Route path="/oauth-callback" element={<OAuthCallback />} />
        <Route path="/policy" element={<PolicyPage />} />
      </Routes>
    </Router>
  );
};

export default App;
