// OAuthCallback.jsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spin, Layout } from 'antd';
import AppLayout from '../components/AppLayout';

const OAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const fragment = new URLSearchParams(location.hash.slice(1));
    const accessToken = fragment.get('access_token');
    const error = query.get('error');

    if (error) {
      console.error(`Error: ${query.get('error_description')}`);
      navigate('/');
    } else if (accessToken) {
      // Save the access token to session storage
      sessionStorage.setItem('fb_access_token', accessToken);
      navigate('/pages');
    } else {
      console.error('Error retrieving access token');
      navigate('/');
    }
  }, [location, navigate]);

  return (
    <AppLayout>
      <Layout.Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Spin size="large" tip="Loading..." />
      </Layout.Content>
    </AppLayout>
  );
};

export default OAuthCallback;
