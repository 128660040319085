import React from 'react';
import { Layout, Button, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { Title } = Typography;

const HeaderComponent = ({ onLogout, showLogout }) => {
  return (
    <Header style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#f0f2f5' }}>
      <div style={{ display: 'flex', justifyContent: showLogout ? 'space-between' : 'center', alignItems: 'center', height: '100%' }}>
        <Title level={2} className="responsive-title" style={{ color: 'black', margin: 0 }}>
          <img src="/chatbot_12662304.png" alt="Chatbot Icon" style={{ width: '50px', height: '50px', marginRight: '8px' }} />
        </Title>
        {showLogout && (
          <Button
            type="primary"
            danger
            icon={<LogoutOutlined />}
            size="large"
            onClick={onLogout}
            className="logout-button"
          >
            Logout
          </Button>
        )}
      </div>
    </Header>
  );
};

export default HeaderComponent;
