// ActionButtonComponent.jsx
import React from 'react';
import { Button, Modal } from 'antd';
import { CloudSyncOutlined, DisconnectOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const { confirm } = Modal;

const ActionButtonComponent = ({ selectedPage, hasSubscription, onConfirm }) => {
  const screens = useBreakpoint();

  const showModal = () => {
    confirm({
      title: `Bạn có muốn ${hasSubscription ? `disconnect chatbot từ page` : `connect chatbot vào page`} ${selectedPage} không ?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        onConfirm();
      },
      onCancel() {},
    });
  };

  return (
    <Button
      type={hasSubscription ? 'primary' : 'default'}
      danger={hasSubscription}
      size="large"
      className="responsive-button"
      icon={hasSubscription ? <DisconnectOutlined /> : <CloudSyncOutlined />}
      onClick={showModal}
      style={screens.xs ? { position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 } : {}}
    >
      {hasSubscription ? 'Disconnect Bot' : 'Connect Bot'}
    </Button>
  );
};

export default ActionButtonComponent;
